export const coreAdvantageList = [
  {
    key: 1,
    imgName: "aboutUs_coreAdvantage_productCan",
    title: "产品能⼒",
    info: "超预期满⾜⽤户核⼼需求，多款App排名领域领先。",
  },
  {
    key: 2,
    imgName: "aboutUs_coreAdvantage_stateBarriers",
    title: "⽣态壁垒",
    info: "从不同的场景中获得⽤户并能满⾜⽤户各个阶段的需求⼀次获客，多次变现。",
  },
  {
    key: 3,
    imgName: "aboutUs_coreAdvantage_rdCapability",
    title: "研发能⼒",
    info: "攻克了行业多项前沿技术，多条业务线同时推进。",
  },
  {
    key: 4,
    imgName: "aboutUs_coreAdvantage_founder",
    title: "创始⼈",
    info: "音乐教育+互联网产品技术多领域专家，连续成功创业者，首席产品体验官。",
  },
];

export const coreTeamList = [
  {
    key: 1,
    imgName: "aboutUs_coreTeam_CEO",
    name: "李帅",
    title: "CEO",
    infos: [
      "钢琴⼗级，琴童家⻓",
      "连续成功创业者",
      "移动产品流量专家",
      "美团早期iOS技术负责⼈",
      "毕业于华科计算机系",
    ],
  },
  {
    key: 2,
    imgName: "aboutUs_coreTeam_CTO",
    name: "周志华",
    title: "CTO",
    infos: [
      "全面负责技术部工作，",
      "擅长音乐AI人工智能技术",
      "连续成功创业者",
      "全栈⼯程师",
      "⼈⼈⽹资深后端架构师",
    ],
  },
  {
    key: 3,
    imgName: "aboutUs_coreTeam_CPO",
    name: "马钊",
    title: "CPO",
    infos: [
      "全面负责公司产品策略制定和实施，",
      "以及公司品牌发展策略规划。",
      "原美团⾦融⽀付业务负责⼈",
      "美团移动后端技术leader",
      "美团8年员⼯",
    ],
  },
];
